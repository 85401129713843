import { render, staticRenderFns } from "./Sign-Up.vue?vue&type=template&id=8630ddac&scoped=true"
import script from "./Sign-Up.vue?vue&type=script&lang=js"
export * from "./Sign-Up.vue?vue&type=script&lang=js"
import style0 from "./Sign-Up.vue?vue&type=style&index=0&id=8630ddac&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8630ddac",
  null
  
)

export default component.exports